<template>
  <v-card outlined class="ma-4">

    <!-- header -->
    <v-toolbar flat dense color="primary">
      <v-toolbar-title>{{ competitor.info.name }}</v-toolbar-title>
    </v-toolbar>

    <!-- tabs -->
    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" show-arrows color="primary" v-if="state === 'ok'">

      <!-- navigation -->
      <v-tab>
        <v-icon left>mdi-information-outline</v-icon>
        INFORMACJE
        <v-spacer/>
      </v-tab>
      <v-tab v-if="$env.APP_MODE === 'ppo'">
        <v-icon left>mdi-trophy-outline</v-icon>
        REKORDY
        <v-spacer/>
      </v-tab>
      <v-tab>
        <v-icon left>mdi-format-list-numbered</v-icon>
        WYNIKI
        <v-spacer/>
      </v-tab>
      <!--<v-tab>
        <v-icon left>mdi-chart-box-outline</v-icon>
        STATYSTYKI
        <v-spacer/>
      </v-tab>-->

      <!-- info -->
      <v-tab-item :class="$vuetify.breakpoint.mdAndUp ? 'border-left-' + theme : ''">
        <v-card flat min-height="300">
          <v-card-text>
            <v-row>
              <v-col class="pa-4 text-center">
                <span class="title">{{ $env.APP_MODE === 'ppo' ? 'RA' : 'WCA' }} ID</span><br>
                <span class="overline primary--text">
                  {{ $env.APP_MODE === 'ppo' ? competitor.info.ra_id : competitor.info.wca_id }}
                </span>
              </v-col>
              <v-col class="pa-4 text-center">
                <span class="title">Państwo</span><br>
                <span class="subtitle-1">
                  <v-img :src="'/flags/' + competitor.info.country.toLowerCase() + '.svg'"
                         height="24" width="32" class="ml-auto mr-auto"
                         :style="[$vuetify.theme.dark ? {'border': '1px solid #2f2f2f'} : {'border': '1px solid #e0e0e0'}]"/>
                      {{ $utils.countries.getCountryName(competitor.info.country) }}
                </span>
              </v-col>
              <v-col class="pa-4 text-center">
                <span class="title">Płeć</span><br>
                <span class="subtitle-1">{{ competitor.info.gender === 'm' ? 'mężczyzna' : 'kobieta' }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-4 text-center">
                <span class="title">Zawody</span><br>
                <span class="subtitle-1">{{ competitor.info.comps }}</span>
              </v-col>
              <v-col class="pa-4 text-center">
                <span class="title">Ułożenia</span><br>
                <span class="subtitle-1">{{ competitor.info.solves }}</span>
              </v-col>
              <v-col class="pa-4 text-center">
                <span class="title">Medale</span><br>
                <span class="subtitle-1">
                  {{ competitor.info.gold }}<v-icon color="yellow darken-1">mdi-medal</v-icon>
                  {{ competitor.info.silver }}<v-icon color="grey darken-1">mdi-medal</v-icon>
                  {{ competitor.info.bronze }}<v-icon color="brown darken-1">mdi-medal</v-icon>
                  ({{ competitor.info.medals }})
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider v-if="competitor.info.wca_id != null"/>
        <v-card flat v-if="competitor.info.wca_id != null">
          <v-card-text>
            <v-row>
              <v-col class="text-left" v-if="$env.APP_MODE === 'ppo'">
                {{ competitor.info.gender === 'm' ? 'Zawodnik brał' : 'Zawodniczka brała' }}
                również udział w zawodach WCA
              </v-col>
              <v-col v-else>&nbsp;</v-col>
              <v-col class="text-right">
                <v-btn elevation="0" style="text-transform: unset" color="primary"
                       @click="openProfileInNewTab(competitor.info.wca_id)">
                  Profil WCA
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- records -->
      <v-tab-item :class="$vuetify.breakpoint.mdAndUp ? 'border-left-' + theme : ''" v-if="$env.APP_MODE === 'ppo'">
        <v-card flat min-height="300">
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th class="text-left">Konkurencja</th>
                  <th class="text-center">Pozycja</th>
                  <th class="text-center">Najlepsze</th>
                  <th class="text-center">Średnia</th>
                  <th class="text-center">Pozycja</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="record in competitor.records" :key="record['s']['event']">
                  <td class="text-right">
                    <v-icon>{{ $utils.events.getEventIcon(record['s']['event']) }}</v-icon>
                  </td>
                  <td class="text-left">
                    {{ $utils.events.getEventName(record['s']['event']) }}
                  </td>
                  <td class="text-center"
                      :class="{'font-weight-bold': record['s']['global_rank'] <= 10, 'primary--text': record['s']['global_rank'] <= 10}">
                    {{ record['s']['global_rank'] }}
                  </td>
                  <td class="text-center font-weight-bold">
                    {{ $utils.results.parseResult(record['s']['result'], record['s']['event']) }}
                  </td>
                  <td class="text-center font-weight-bold">
                    {{
                      record['a'] != null ? $utils.results.parseResult(record['a']['result'], record['a']['event']) : ''
                    }}
                  </td>
                  <td class="text-center"
                      :class="{'font-weight-bold': record['a'] != null && record['a']['global_rank'] <= 10, 'primary--text': record['a'] != null && record['a']['global_rank'] <= 10}">
                    {{ record['a'] != null ? record['a']['global_rank'] : '' }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- results -->
      <v-tab-item :class="$vuetify.breakpoint.mdAndUp ? 'border-left-' + theme : ''">
        <v-card flat min-height="300">
          <v-card-text>
            <v-toolbar-title>Konkurencja</v-toolbar-title>
            <v-chip-group column mandatory v-model="results_event">
              <v-chip v-for="(event, index) in results_events" :key="event" outlined
                      :color="index === results_event ? 'primary' : ''"
                      class="ma-1" style="padding-top: 2px; padding-left: 13px">
                <v-icon>{{ $utils.events.getEventIcon(event) }}</v-icon>
              </v-chip>
            </v-chip-group>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Zawody</th>
                  <th class="text-left">Runda</th>
                  <th class="text-center">Miejsce</th>
                  <th class="text-center">Najlepsze</th>
                  <th class="text-center">Średnia</th>
                  <th class="text-center" colspan="5">Ułożenia</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(result, index) in competitor.results[results_event]" :key="index">
                  <td class="text-left" nowrap>
                    {{
                      (index === 0 || competitor.results[results_event][index - 1]['comp_id'] !== result['comp_id']) ? result['comp_name'] : ''
                    }}
                  </td>
                  <td class="text-left" nowrap>{{ $utils.events.getRoundName(result['round']) }}</td>
                  <td class="text-center" nowrap>{{ result['ranking'] }}</td>
                  <td class="text-center font-weight-bold" nowrap :class="{'primary--text': result['pb_single']}">
                    {{ $utils.results.parseResult(result['best'], result['event']) }}
                  </td>
                  <td class="text-center font-weight-bold" nowrap :class="{'primary--text': result['pb_average']}">
                    {{ $utils.results.parseResult(result['average'], result['event']) }}
                  </td>
                  <td class="text-center" nowrap
                      :style="[$vuetify.theme.dark ? {'border-left': '2px solid #2f2f2f'} : {'border-left': '2px solid #e0e0e0'}]">
                    {{ $utils.results.parseResult(result['a1'], result['event']) }}
                  </td>
                  <td class="text-center" nowrap>{{ $utils.results.parseResult(result['a2'], result['event']) }}</td>
                  <td class="text-center" nowrap>{{ $utils.results.parseResult(result['a3'], result['event']) }}</td>
                  <td class="text-center" nowrap>{{ $utils.results.parseResult(result['a4'], result['event']) }}</td>
                  <td class="text-center" nowrap>{{ $utils.results.parseResult(result['a5'], result['event']) }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- statistics -->
      <!--<v-tab-item :class="$vuetify.breakpoint.mdAndUp ? 'border-left-' + theme : ''">
        <v-card flat min-height="300">
          <v-card-text>
            Aktualnie trwają prace nad statystykami.
          </v-card-text>
        </v-card>
      </v-tab-item>-->

    </v-tabs>

    <!-- loading -->
    <v-skeleton-loader v-if="state === 'loading'" type="card"/>

    <!-- error -->
    <v-alert v-if="state === 'error'">
      <v-icon left color="error">mdi-alert-circle-outline</v-icon>
      Wystąpił nieoczekiwany błąd.
    </v-alert>

  </v-card>
</template>

<script>
export default {
  name: 'Competitor',
  data: () => ({
    state: 'loading',
    competitor: {info: {name: ''}},
    results_events: [],
    results_event: 0
  }),
  created: function () {
    this.$http.post('/api/competitor', {id: this.$route.params.id}).then((res) => {
      this.competitor.info = res.data.info;
      if (Object.keys(this.competitor.info).length !== 0) {
        this.competitor.records = this.parseRecords(res.data.records);
        this.results_events = this.getEvents(res.data.results);
        this.competitor.results = this.parseResults(res.data.results, this.results_events);
        this.state = 'ok';
      } else this.state = 'error';
    }).catch((e) => {
      this.state = 'error';
      console.log(e);
    });
  },
  methods: {
    openProfileInNewTab(wcaId) {
      window.open('https://www.worldcubeassociation.org/persons/' + wcaId, '_blank');
    },
    parseRecords(records) {
      let parsedRecords = [];
      for (let i = 0; i < 17; i++)
        parsedRecords.push({'s': null, 'a': null});
      for (let i = 0; i < records.length; i++)
        parsedRecords[records[i]['event']][records[i]['type']] = records[i]
      parsedRecords = parsedRecords.filter((item) => item['s'] != null || item['a'] != null);
      return parsedRecords;
    },
    getEvents(results) {
      let events = []
      for (let i = 0; i < results.length; i++)
        events.push(results[i]['event']);
      events = events.sort().filter((item, index, array) => !index || item !== array[index - 1]);
      return events.sort((a, b) => (+a) - (+b));
    },
    parseResults(results, events) {
      let parsedResults = [];
      for (let i = 0; i < events.length; i++) {
        parsedResults.push([]);
        for (let j = 0; j < results.length; j++)
          if (results[j]['event'] === events[i])
            parsedResults[i].push(results[j]);
        let pbSingle = 9999999999, pbAverage = 9999999999;
        for (let j = parsedResults[i].length - 1; j >= 0; j--) {
          if (0 < parsedResults[i][j]['best'] && parsedResults[i][j]['best'] <= pbSingle) {
            parsedResults[i][j]['pb_single'] = true;
            pbSingle = parsedResults[i][j]['best'];
          } else parsedResults[i][j]['pb_single'] = false;
          if (0 < parsedResults[i][j]['average'] && parsedResults[i][j]['average'] <= pbAverage) {
            parsedResults[i][j]['pb_average'] = true;
            pbAverage = parsedResults[i][j]['average'];
          } else parsedResults[i][j]['pb_average'] = false;
        }
      }
      return parsedResults;
    }
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    }
  }
};
</script>

<style scoped>
.border-left-light {
  border-left: 1px solid #e0e0e0;
}

.border-left-dark {
  border-left: 1px solid #2f2f2f;
}

.theme--light.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #00000010;
}

.theme--dark.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #ffffff10;
}
</style>
